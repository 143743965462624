import { createSlice } from '@reduxjs/toolkit';
import { LatLngLiteral } from 'leaflet';
import { Announce, AnnounceMap, Filter } from '../../models/Announces';
import {
  checkAnnouncesCount,
  getAnnounces,
  getAnnouncesFilters,
  getMapAnnounces,
  saveAnnounce,
} from './announcesThunk';

type AnnouncesState = {
  announces: Announce[];
  announcesMap: AnnounceMap[];
  announce: Announce | null;
  filters: Filter[];
  totalCount: number;
  mapTotalCount: number;
  newAnnouncesCount: number;
  loading: boolean;
  loadingFilters: boolean;
  error: any;
  centerMap: LatLngLiteral;
  radius: number;
  stateCountyId: string | null;
  stateTownId: string | null;
};

const initialState: AnnouncesState = {
  announces: [],
  announcesMap: [],
  mapTotalCount: 0,
  filters: [],
  announce: null,
  totalCount: 0,
  newAnnouncesCount: 0,
  loading: false,
  loadingFilters: false,
  error: null,
  centerMap: {
    lat: 44.43225,
    lng: 26.10626,
  },
  radius: 30,
  stateCountyId: null,
  stateTownId: null,
};

const announcesSlice = createSlice({
  name: 'announces',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    toggleChecked(state, action) {
      const item = state.announces.find(item => item.id === action.payload);

      if (item) {
        item.checked = !item.checked;
      }
    },
    checkAll(state, action) {
      state.announces.forEach(item => (item.checked = action.payload));
    },
    setStateCenter(state, action) {
      state.centerMap = action.payload;
    },
    setStateRadius(state, action) {
      state.radius = action.payload;
    },
    setStateCounty(state, action) {
      state.stateCountyId = action.payload;
    },
    setStateTown(state, action) {
      state.stateTownId = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAnnounces.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAnnounces.fulfilled, (state, { payload }) => {
        state.totalCount = payload.totalCount;
        state.announces = payload.data;
        state.newAnnouncesCount = 0;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAnnounces.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getMapAnnounces.fulfilled, (state, { payload }) => {
        state.mapTotalCount = payload.totalCount;
        state.announcesMap = payload.data;
      })
      .addCase(getAnnouncesFilters.pending, state => {
        state.loadingFilters = true;
        state.error = null;
      })
      .addCase(getAnnouncesFilters.fulfilled, (state, { payload }) => {
        state.filters = payload;
        state.loadingFilters = false;
        state.error = null;
      })
      .addCase(getAnnouncesFilters.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingFilters = false;
      })
      .addCase(saveAnnounce.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveAnnounce.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveAnnounce.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(checkAnnouncesCount.fulfilled, (state, { payload }) => {
        state.newAnnouncesCount = payload;
      });
  },
});

export const {
  clearError,
  toggleChecked,
  checkAll,
  setStateCenter,
  setStateRadius,
  setStateCounty,
  setStateTown,
} = announcesSlice.actions;

export default announcesSlice.reducer;
