// import moment from 'moment';
import React from 'react';
import IconPDF from '../../icons/IconPDF';
import IconSearchL from '../../icons/IconSearchL';
import './PriceEvaluationGrafic.scss';

interface Props {}
const appliedFiltres = [
  { title: 'Locație:', label: 'Botanica' },
  { title: 'Fondul locativ:', label: 'Bloc Secundar' },
  { title: 'Număr de camere:', label: '3 camere' },
  { title: 'Planimetrie:', label: 'Seria MS' },
  { title: 'Living:', label: 'Nu' },
  { title: 'Stare Imobil:', label: 'Reparație Euro' },
  {
    title: 'Suprafata totală, m²:',
    label: '70',
  },
  { title: 'Blocuri sanitare:', label: '2' },
  { title: 'Etaj:', label: '7' },
  { title: 'Încălzire:', label: 'Centralizată' },
  { title: 'Total etaje:', label: '9' },
  { title: 'Compania de construcție:', label: 'Not set' },
];

const LeftConclusionsEvaluations = [
  { title: 'Preț imobil', label: '€ 80 000' },
  { title: 'Preț m²', label: '€ 80 000' },
];
const rightConclusionsEvaluations = [
  { title: 'Preț m² SubEvaluat cu', label: '€ -16' },
  { title: 'Preț m² Recomandat', label: '€ 1 159' },
  { title: 'Preț Recomandat', label: '€ 77 074 - € 85 187' },
];

const PriceEvaluationGrafic: React.FC<Props> = () => {
  return (
    <div className='bg-pink price-evaluation-grafic'>
      <div className='price-evaluation-grafic-container'>
        <form action=''>
          <div className='search-container'>
            <label htmlFor='imobil-price' className='form-item-group-label'>
              Evaluare Pret Imobil
            </label>
            <div className='form-item-group-block '>
              <input
                type='search'
                className='form-item-control'
                placeholder='Au fost analizate 503 anunturi'
                name='query'
                id='imobil-price'
                value={''}
                // onChange={(event: any) => setQuery(event.target.value)}
              />
              <button className='icon' aria-label='Cauta' type='submit'>
                <IconSearchL />
              </button>
            </div>
          </div>
        </form>
        {appliedFiltres?.length > 0 && (
          <div className='applied-filtres'>
            <p className='heading-lg'>Filtre aplicate</p>
            <div className='applied-filtres-list'>
              {appliedFiltres?.map(item => (
                <div className='applied-filtres-list__item'>
                  {item?.title && (
                    <div className='applied-filtres-list__item-title'>
                      {item.title}
                    </div>
                  )}
                  {item?.label && (
                    <div className='applied-filtres-list__item-label'>
                      <span className='label-item-tag'>{item.label}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <div className='price-evaluation-grafic__table'>
          <table>
            <tr>
              <th colSpan={3}>
                <p className='heading-lg'>Evaluare Pretului de Piata</p>
              </th>
            </tr>
            <tr>
              <td className='td-left'>
                <span className='label-red'>+15%</span>
                <p>PREȚ SUPRAEVALUAT</p>
              </td>
              <td>
                <span className='triangle triangle-top-red'></span>
              </td>
              <td className='td-right '>
                <span className='label-red'>+15%</span>
              </td>
            </tr>
            <tr>
              <td className='td-left'>
                <span className='label-red'>+10%</span>
                <p>PREȚ MARE</p>
              </td>
              <td>
                <span className='triangle triangle-second-red'></span>
              </td>
              <td className='td-right'>
                <span className='label-red'>10%</span>
              </td>
            </tr>
            <tr>
              <td className='td-left'>
                <span className='label-yellow'>+5%</span>
                <p>PREȚ MEDIU DE PIATA</p>
                <span className='label-yellow'>-5%</span>
              </td>
              <td>
                <span className='triangle triangle-third-yellow'>
                  <span className='label-white'>80.000 €</span>
                </span>
              </td>
              <td className='td-right'>
                <div className='table-flex'>
                  <div className='text-block'>
                    <p>Probabilitatea de Vanzare (In Decurs de 60 de Zile)</p>
                  </div>
                  <div className='lable-list'>
                    <span className='label-yellow'>50%</span>

                    <span className='label-yellow'>50%</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className='td-left'>
                <p>PREȚ BUN</p>
                <span className='label-green'>-5%</span>
              </td>
              <td>
                <span className='triangle triangle-fourth-green'></span>
              </td>
              <td className='td-right'>
                <span className='label-green'>70%</span>
              </td>
            </tr>
            <tr>
              <td className='td-left'>
                <p>PREȚ SUPER BUN</p>
                <span className='label-green'>-15%</span>
              </td>
              <td>
                <span className='triangle triangle-fifth-green'></span>
              </td>
              <td className='td-right'>
                <span className='label-green'>99%</span>
              </td>
            </tr>
          </table>
        </div>

        <div className='conclusions-evaluation'>
          <p className='heading-lg'>Concluzii Evaluare</p>
          {LeftConclusionsEvaluations?.length > 0 && (
            <div className='conclusions-evaluation__list conclusions-evaluation__list-left'>
              {LeftConclusionsEvaluations?.map(item => (
                <div className='conclusions-evaluation-list__item'>
                  {item?.title && (
                    <div className='conclusions-evaluation-list__item-title'>
                      {item.title}
                    </div>
                  )}
                  {item?.label && (
                    <div className='conclusions-evaluation__item-label'>
                      <span className='label-item-tag'>{item.label}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {rightConclusionsEvaluations?.length > 0 && (
            <div className='conclusions-evaluation__list conclusions-evaluation__list-right'>
              {rightConclusionsEvaluations?.map(item => (
                <div className='conclusions-evaluation-list__item'>
                  {item?.title && (
                    <div className='conclusions-evaluation-list__item-title'>
                      {item.title}
                    </div>
                  )}
                  {item?.label && (
                    <div className='conclusions-evaluation__item-label'>
                      <span className='label-item-tag'>{item.label}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='price-evaluation-grafic__buttons'>
          <a href='' className='button button-border'>
            <IconPDF /> Descarca PDF
          </a>
        </div>
      </div>
    </div>
  );
};

export default PriceEvaluationGrafic;
