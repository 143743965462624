import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  Alignment,
  Autoformat,
  Bold,
  ClassicEditor,
  Essentials,
  GeneralHtmlSupport,
  Indent,
  IndentBlock,
  Italic,
  List,
  Mention,
  Paragraph,
  PasteFromOffice,
  Undo,
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { ContractTemplateForm } from '../../models/ContractTemplates';
import Input from '../Input';
import './AddContractTemplateForm.scss';

interface Props {
  formData: ContractTemplateForm;
  setFormData: (form: ContractTemplateForm) => void;
  submitTemplate: () => void;
  previewTemplate: () => void;
}

const AddContractTemplateForm: React.FC<Props> = ({
  formData,
  setFormData,
  submitTemplate,
  previewTemplate,
}) => {
  const { id } = useParams();

  const { contractTemplate } = useAppSelector(state => state.contractTemplates);
  const [title, setTitle] = useState('');

  useEffect(() => {
    contractTemplate?.id && id && setTitle(contractTemplate?.title);
    // eslint-disable-next-line
  }, [contractTemplate]);

  useEffect(() => {
    setFormData({ ...formData, title: title });
    // eslint-disable-next-line
  }, [title]);

  return (
    <>
      <div className='add-contract-template-form'>
        <form action=''>
          <div className='form-item'>
            <Input
              labelText={true}
              inlineLabel='Denumirea sablonului'
              value={title ?? ''}
              onChange={e => setTitle(e.target.value)}
            />
          </div>
          <label htmlFor='' className='form-item-group-label'>
            Editor
          </label>
          <div className='form-editor'>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: {
                  items: [
                    'undo',
                    'redo',
                    '|',
                    'bold',
                    'italic',
                    'bulletedList',
                    'numberedList',
                    'outdent',
                    'indent',
                    'autoformat',
                    'alignment',
                  ],
                },
                plugins: [
                  Bold,
                  Essentials,
                  Italic,
                  Mention,
                  Paragraph,
                  Undo,
                  List,
                  Indent,
                  IndentBlock,
                  Autoformat, // Load the open-source feature.
                  PasteFromOffice,
                  GeneralHtmlSupport,
                  Alignment,
                ],
                initialData: formData.content || '',
              }}
              data={formData.content || ''}
              onChange={(event, editor) => {
                const content = editor.getData();
                setFormData({ ...formData, content: content });
              }}
            />
          </div>
          <div className='display-flex buttons-group'>
            <button
              className='button button-blue'
              onClick={e => {
                e.preventDefault();
                submitTemplate();
              }}
            >
              Salveaza
            </button>
            <button
              className='button button-border'
              onClick={e => {
                e.preventDefault();
                previewTemplate();
              }}
            >
              Previzualizare
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddContractTemplateForm;
