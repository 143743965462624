import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import ModalPDF from '../../components/ModalPDF';
import IconPDF from '../../icons/IconPDF';
import IconPencil from '../../icons/IconPencil';
import IconTrash from '../../icons/IconTrash';
import './PriceEvaluationListItem.scss';

interface Props {}

const PriceEvaluationListItem: React.FC<Props> = () => {
  const [isChecked, setIsChecked] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div
      className={`announces-list-item item-price-evaluation ${isChecked && 'active'}`}
    >
      {/* <div className='mobile-lines'>
        <div className='line-border'></div>
        <div className='checkbox-container'>
          <Form.Group id='formGridCheckbox'>
            <Form.Check
              type='checkbox'
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </Form.Group>
        </div>
        <div className='line-border'></div>
      </div> */}
      <div className='list-item-content'>
        <div className='column-item'>
          <div className='mobile-text'>ID</div>
          <div className='info-nr label-item-mobile'>#345678</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Data adaugare</div>
          <div className='info-date label-item-mobile'>21.04.2024</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Filtre</div>
          <div className='info-list display-flex flex-wrap'>
            <span className='label-item-tag'>Vanzare</span>
            <span className='label-item-tag'>Cluj-Napoca</span>
            <span className='label-item-tag'>SU: 78 mp</span>
            <span className='label-item-tag'>AN: 1977</span>
            <span className='label-item-tag'>Etaj: 7</span>
            <span className='label-item-tag'>2 camere</span>
            <span className='label-item-tag'>Pana la €300 000</span>
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Anunturi analizate</div>
          <div className=' label-item-mobile'>4 300</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Pret mediu</div>
          <div className='info-price label-item-mobile'>
            <span className='label-item-tag'>€ 300 000</span>
          </div>
        </div>
        <div className='line-border'></div>
        <div className='info-icons'>
          <button
            className='button-square-md'
            onClick={() => setShowConfirmModal(true)}
            data-tooltip-id='pdf'
            data-tooltip-content='PDF'
          >
            <IconPDF />
          </button>
          <Tooltip id='pdf' />
          <ModalPDF
            showModal={showConfirmModal}
            hideModal={() => setShowConfirmModal(false)}
          />

          <button className='button-square-md' aria-label=''>
            <IconPencil />
          </button>
          <button
            className='button-square-md'
            data-tooltip-id='remove'
            data-tooltip-content='Sterge'
            aria-label='Sterge'
            onClick={e => {
              e.preventDefault();
              setShowDeleteModal(true);
            }}
          >
            <IconTrash />
          </button>
          <Tooltip id='remove' />
        </div>
      </div>
    </div>
  );
};

export default PriceEvaluationListItem;
