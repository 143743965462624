// import moment from 'moment';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import Input from '../Input';
import Select from '../Select';
import './FilterListNewEvaluation.scss';

interface Props {}
const options = [
  { label: 'Ore', value: 'HOURS' },
  { label: 'Minute', value: 'MINUTES' },
  { label: 'Zile', value: 'DAYS' },
];

const FilterListNewEvaluation: React.FC<Props> = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());

  return (
    <div className='new-evaluation-list-filter'>
      <form>
        <div className='filter-item-top'>
          <div className='filter-item'>
            <Select labelText={true} inlineLabel='Property type' />
          </div>
        </div>
        <div className='filter-form-container'>
          <div className='filter-item'>
            <Select labelText={true} inlineLabel='Tip Oferta' />
          </div>
          <div className='filter-item'>
            <Select labelText={true} inlineLabel='Judet' />
          </div>
          <div className='filter-item'>
            <Select labelText={true} inlineLabel='Zona' />
          </div>
          <div className='filter-item'>
            <label
              htmlFor='build-year-new-evolution'
              className='form-item-group-label'
            >
              An constructie
            </label>
            <div className='form-group flex-input-row '>
              <Input
                labelText={false}
                placeholder='De la'
                type='number'
                value={''}
                id='build-year-new-evolution'
              />
              <Input
                labelText={false}
                placeholder='Pana la'
                type='number'
                value={''}
              />
            </div>
          </div>
          <div className='filter-item'>
            <label
              htmlFor='price-new-evolution'
              className='form-item-group-label'
            >
              Pret
            </label>
            <div className='form-group flex-input-row '>
              <Input
                labelText={false}
                placeholder='De la'
                type='number'
                value={''}
                id='price-new-evolution'
              />
              <Input
                labelText={false}
                placeholder='Pana la'
                type='number'
                value={''}
              />
            </div>
          </div>
          <div className='filter-item'>
            <label
              htmlFor='roomsnr-new-evolution'
              className='form-item-group-label'
            >
              Nr. Camere
            </label>
            <div className='form-group flex-input-row '>
              <Input
                labelText={false}
                placeholder='De la'
                type='number'
                value={''}
                id='roomsnr-new-evolution'
              />
              <Input
                labelText={false}
                placeholder='Pana la'
                type='number'
                value={''}
              />
            </div>
          </div>
          <div className='filter-item'>
            <Select labelText={true} inlineLabel='Tip Specific' />
          </div>
          <div className='filter-item'>
            <Select labelText={true} inlineLabel='Oras' />
          </div>
          <div className='filter-item'>
            <label className='form-item-group-label'>Etaj</label>
            <div className='form-group flex-input-row select-arrow-right'>
              <Select labelText={false} />
              <Select labelText={false} />
            </div>
          </div>
          <div className='filter-item'>
            <label
              htmlFor='suprafata-new-evolution'
              className='form-item-group-label'
            >
              Suprafata Utila
            </label>
            <div className='form-group flex-input-row '>
              <Input
                labelText={false}
                placeholder='De la'
                type='number'
                value={''}
                id='suprafata-new-evolution'
              />
              <Input
                labelText={false}
                placeholder='Pana la'
                type='number'
                value={''}
              />
            </div>
          </div>
          <div className='filter-item'>
            <label
              htmlFor='suprafata-new-evolution-2'
              className='form-item-group-label'
            >
              Suprafata Utila
            </label>
            <div className='form-group flex-input-row '>
              <Input
                labelText={false}
                placeholder='De la'
                type='number'
                value={''}
                id='suprafata-new-evolution-2'
              />
              <Input
                labelText={false}
                placeholder='Pana la'
                type='number'
                value={''}
              />
            </div>
          </div>

          <div className='filter-item'>
            <label className='form-item-group-label'>Selecteaza perioada</label>
            <div className='date-picker date'>
              <div className='flex-range'>
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  className={''}
                />
              </div>
            </div>
          </div>
          <div className='filter-item'>
            <Form.Group id='etaj-nespecificat'>
              <Form.Check
                type='checkbox'
                label='Exclus etaj nespecificat'
                checked={false}
              />
            </Form.Group>
          </div>
          <div className='filter-item'>
            <Form.Group id='etaj-nespecificat'>
              <Form.Check
                type='checkbox'
                label='Exclus etaj nespecificat'
                checked={false}
              />
            </Form.Group>
          </div>
        </div>
        <div className='filtre-buttons display-flex'>
          <button className='button button-border'>Reseteaza</button>
          <button className='button button-blue'>Filtreaza</button>
        </div>
      </form>
    </div>
  );
};

export default FilterListNewEvaluation;
