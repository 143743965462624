import React from 'react';
import './FilterListHeaderPriceEvaluation.scss';

interface Props {}
const FilterListHeaderPriceEvaluation: React.FC<Props> = () => {
  return (
    <div className='filter-list-container filter-header-price-evaluation-list-item'>
      <form>
        {/* <Form.Group id='formGridCheckbox'>
          <Form.Check type='checkbox' />
        </Form.Group> */}
        <div className='filter-item arrows-label-icon'>
          <label>ID</label>
        </div>
        <div className='filter-item arrows-label-icon'>
          <label>Data adaugare</label>
        </div>
        <div className='filter-item'>
          <label>Filtre</label>
        </div>
        <div className='filter-item'>
          <label>Anunturi analizate</label>
        </div>
        <div className='filter-item'>
          <label>Pret mediu</label>
        </div>
        <div className='filter-item'>
          <label>Actiuni</label>
        </div>
      </form>
    </div>
  );
};
export default FilterListHeaderPriceEvaluation;
