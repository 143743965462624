import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  getContract,
  previewContract,
} from '../../redux/contracts/contractsThunk';
import { useAppDispatch } from '../../redux/hooks';
import useHelper from '../../helpers/useHelper';
import {
  Contract,
  ContractFormData,
  ContractPreview,
} from '../../models/Contract';
import IconPencilWriting from '../../icons/IconPencilWriting';
import IconTimes from '../../icons/IconTimes';
import Spinner from '../Spinner';
import './ModalPDF.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  showModal: boolean;
  item?: Contract | ContractFormData;
  hideModal: () => void;
}

const ModalPDF: React.FC<Props> = ({ item, showModal, hideModal }) => {
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();
  const [base64Data, setBase64Data] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (item) {
      if ('id' in item) {
        getContractFunction(item);
      }

      if ('contractTemplateId' in item) {
        item?.contractTemplateId &&
          preview({
            contractTemplateId: item.contractTemplateId,
            data: item?.data
              ?.filter((item: any) => item.value?.length > 0)
              .map((item: any) => ({ slug: item.slug, value: item.value })),
          });
      }
    }
    // eslint-disable-next-line
  }, []);

  const getContractFunction = async (contract: Contract) => {
    try {
      const response = await dispatch(getContract(contract.id));
      unwrapResult(response);

      preview({
        contractTemplateId: response?.payload?.contractTemplate?.id,
        data: response?.payload?.data
          ?.filter((item: any) => item.value?.length > 0)
          .map((item: any) => ({ slug: item.slug, value: item.value })),
      });
    } catch (err: any) {}
  };

  const preview = async (contract: ContractPreview) => {
    try {
      const resultPreview = await dispatch(previewContract(contract));
      unwrapResult(resultPreview);

      const blob = resultPreview.payload.data;

      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setBase64Data(`${reader.result}#toolbar=0&navpanes=0&scrollbar=0`);
        } else {
          console.error('Error: FileReader result is not a string');
        }
      };
      reader.readAsDataURL(blob);
    } catch (err: any) {
      hideModal();
      diplayErrorMessages(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={hideModal}
        animation={false}
        className='doc-modal'
      >
        <button
          className='close-modal'
          aria-label='Închide'
          onClick={hideModal}
        >
          <IconTimes />
        </button>
        <Modal.Body>
          <div
            style={{
              width: '100%',
              height: '500px',
            }}
          >
            {loading ? (
              <Spinner />
            ) : base64Data ? (
              <object
                data={base64Data}
                type='application/pdf'
                width='100%'
                height={'100%'}
              >
                <p>
                  Your browser does not support inline PDFs.{' '}
                  <a href={base64Data} download='document.pdf'>
                    Download the PDF
                  </a>
                </p>
              </object>
            ) : (
              <p>No PDF yet.</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='button-blue button-md'
            onClick={() => {
              hideModal();
            }}
          >
            Semneaza <IconPencilWriting />
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalPDF;
