// import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import Input from '../Input';
import Select from '../Select';
import './FilterListPriceEvaluation.scss';

interface Props {}
const options = [
  { label: 'Ore', value: 'HOURS' },
  { label: 'Minute', value: 'MINUTES' },
  { label: 'Zile', value: 'DAYS' },
];

const FilterListPriceEvaluation: React.FC<Props> = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  return (
    <div className='bg-white filter-list-price-evaluation-list-filter'>
      <form>
        <div className='filter-item'>
          <Select labelText={true} inlineLabel='Tip proprietate' />
        </div>
        <div className='filter-item'>
          <Select labelText={true} inlineLabel='Tip Oferta' />
        </div>
        <div className='filter-item'>
          <Select labelText={true} inlineLabel='Tip Imobil' />
        </div>
        <div className='filter-item'>
          <Select labelText={true} inlineLabel='Nr. camere' />
        </div>
        <div className='filter-item'>
          <Select labelText={true} inlineLabel='Judet' />
        </div>

        <div className='filter-item '>
          <div className='more-filters'>
            <Select labelText={true} placeholder='Mai multe filtre' />
          </div>
        </div>
      </form>
    </div>
  );
};

export default FilterListPriceEvaluation;
