import React, { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import ImageItem from '../../assets/img/propertylist.png';
import CloseIcon from '../../assets/svg/closemodal.svg';
import IconRingBell from '../../icons/IconRingBell';
import './AlertAddModal.scss';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
}
const props = [
  {
    title: 'title 1',
    content: `Lorem ipsum`,
  },
];
const AlertAddModal: React.FC<Props> = ({ isModalOpen, closeModal }) => {
  const content = useRef(null);

  const onCloseModal = () => {
    closeModal();
  };

  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={onCloseModal}
        animation={true}
        className={'modal alert-modal'}
      >
        <Modal.Header>
          <div className='close-btn-modal' onClick={onCloseModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          <span>
            <IconRingBell /> 3 anunturi noi in baza alertilor
          </span>
        </Modal.Header>
        <Modal.Body>
          <Accordion defaultActiveKey='0' className='accordion-add'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>
                <span className='header-left'>
                  <span className='number'>
                    <strong>1</strong>
                  </span>
                  <span className='title'>
                    Vanzare Bucuresti , zona Calea Victoriei, Cantemir, Cismigi
                  </span>
                </span>
                <span className='result'>20 de anunturi</span>
              </Accordion.Header>
              <Accordion.Body>
                <div className='accordion-list-item'>
                  <div className='column-list-item'>
                    <div className='info-img'>
                      <img src={ImageItem} alt='' />
                    </div>
                    <div className='title'>
                      <strong>
                        Vanzare Teren Agricolzona THEODOR PALLADY,
                      </strong>
                    </div>
                  </div>
                  <div className='column-list-item'>
                    <div className='date'>30.10.2024 15:23</div>
                  </div>
                  <div className='column-list-item'>
                    <div className='price'>
                      <strong>€300 000</strong>
                    </div>
                  </div>
                </div>
                <div className='accordion-list-item'>
                  <div className='column-list-item'>
                    <div className='info-img'>
                      <img src={ImageItem} alt='' />
                    </div>
                    <div className='title'>
                      <strong>Zona Calea Victoriei | Bucuresti, B-IF</strong>
                    </div>
                  </div>
                  <div className='column-list-item'>
                    <div className='date'>30.10.2024 15:23</div>
                  </div>
                  <div className='column-list-item'>
                    <div className='price'>
                      <strong>€643 600</strong>
                    </div>
                  </div>
                </div>
                <div className='accordion-list-item'>
                  <div className='column-list-item'>
                    <div className='info-img'>
                      <img src={ImageItem} alt='' />
                    </div>
                    <div className='title'>
                      <strong>
                        Zona Prelungirea Ghencea | Bucuresti, B-IF
                      </strong>
                    </div>
                  </div>
                  <div className='column-list-item'>
                    <div className='date'>30.10.2024 15:23</div>
                  </div>
                  <div className='column-list-item'>
                    <div className='price'>
                      <strong>€580 300</strong>
                    </div>
                  </div>
                </div>
                <div className='button-block'>
                  <button className='button-blue button-sm'>Vezi toate</button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
              <Accordion.Header>
                <span className='header-left'>
                  <span className='number'>
                    <strong>2</strong>
                  </span>
                  <span className='title'>
                    Vanzare Bucuresti , zona Calea Victoriei, Cantemir, Cismigi
                  </span>
                </span>

                <span className='result'>17 de anunturi</span>
              </Accordion.Header>
              <Accordion.Body>
                <div className='accordion-list-item'>
                  <div className='column-list-item'>
                    <div className='info-img'>
                      <img src={ImageItem} alt='' />
                    </div>
                    <div className='title'>
                      <strong>
                        Vanzare Teren Agricolzona THEODOR PALLADY,
                      </strong>
                    </div>
                  </div>
                  <div className='column-list-item'>
                    <div className='date'>30.10.2024 15:23</div>
                  </div>
                  <div className='column-list-item'>
                    <div className='price'>
                      <strong>€300 000</strong>
                    </div>
                  </div>
                </div>
                <div className='accordion-list-item'>
                  <div className='column-list-item'>
                    <div className='info-img'>
                      <img src={ImageItem} alt='' />
                    </div>
                    <div className='title'>
                      <strong>Zona Calea Victoriei | Bucuresti, B-IF</strong>
                    </div>
                  </div>
                  <div className='column-list-item'>
                    <div className='date'>30.10.2024 15:23</div>
                  </div>
                  <div className='column-list-item'>
                    <div className='price'>
                      <strong>€643 600</strong>
                    </div>
                  </div>
                </div>
                <div className='accordion-list-item'>
                  <div className='column-list-item'>
                    <div className='info-img'>
                      <img src={ImageItem} alt='' />
                    </div>
                    <div className='title'>
                      <strong>
                        Zona Prelungirea Ghencea | Bucuresti, B-IF
                      </strong>
                    </div>
                  </div>
                  <div className='column-list-item'>
                    <div className='date'>30.10.2024 15:23</div>
                  </div>
                  <div className='column-list-item'>
                    <div className='price'>
                      <strong>€580 300</strong>
                    </div>
                  </div>
                </div>
                <div className='button-block'>
                  <button className='button-blue button-sm'>Vezi toate</button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AlertAddModal;
