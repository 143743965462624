import * as locale from 'date-fns/locale';
import moment from 'moment';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import 'react-calendar/dist/Calendar.css';
import { DateRangePicker } from 'react-date-range';
import ReactDatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  clearStreets,
  clearTowns,
  clearZona,
} from '../../../redux/localities/localities-slice';
import {
  getCounty,
  getLocalities,
  getLocations,
  getSpecificTypes,
  getStreet,
  getZona,
} from '../../../redux/localities/localitiesThunk';
import { getPricesTypes } from '../../../redux/properties/propertiesThunk';
import { getUsers } from '../../../redux/users/usersThunk';
import { parseValues } from '../../../helpers/useHelper';
import { Filter, SelectValue } from '../../../models/Properties';
import IconArrowUp from '../../../icons/IconArrowUp';
import AlphabetFilter from '../../AlphabetFilter';
import FormSelect from '../../FormSelect';
import Input from '../../Input/Input';
import MultiselectCheckboxes from '../../MultiselectCheckboxes';
import Spinner from '../../Spinner';
import './FiltersForm.scss';

interface Props {
  activeCategory?: number;
  setActiveCategory?: (e: number) => void;
  filtersForm?: any;
  setFiltersForm?: (e: any) => void;
  openedFilters?: boolean;
  setOpenedFilters?: (e: boolean) => void;
  setCheckedAll?: (all: boolean) => void;
  letterFilter?: any;
  setLetterFilter?: (e: any) => void;
  filterByLettersOnMobile?: boolean;
  setFilterByLettersOnMobile?: (p: boolean) => void;
}

export const contactData = [
  {
    value: '0',
    label: 'Nespecificat',
  },
  {
    value: '1',
    label: 'Cu date de contact',
  },
  {
    value: '2',
    label: 'Fara date de contact',
  },
];

export const roomsData = [
  {
    value: '1',
    label: 'Garsoniera',
  },
  {
    value: '2',
    label: 'Apartament 2 camere',
  },
  {
    value: '3',
    label: 'Apartament 3 camere',
  },
  {
    value: '4',
    label: 'Apartament 4 camere',
  },
  {
    value: '5',
    label: 'Apartament 5+ camere',
  },
];

// interface FilterForm {}

const FiltersForm: React.FC<Props> = ({
  activeCategory,
  setActiveCategory,
  filtersForm,
  setFiltersForm,
  openedFilters = true,
  setOpenedFilters,
  setCheckedAll,
  letterFilter,
  setLetterFilter,
  filterByLettersOnMobile,
  setFilterByLettersOnMobile,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [formData, setFormData] = useState<any>(undefined);
  const [countyId, setCountyId] = useState<string | null>(null);
  const [townId, setTownId] = useState<string | null>(null);
  const { loadingFilters, pricesTypes, loading } = useAppSelector(
    state => state.properties,
  );
  const { filters } = useAppSelector(state =>
    location.pathname === '/ads' || location.pathname.includes('/tag-ads/')
      ? state.announces
      : state.properties,
  );
  const { users } = useAppSelector(state => state.users);
  const { categories } = useAppSelector(state => state.categories);
  const { locations, localities, county, streets, zona, specificTypes } =
    useAppSelector(state => state.localities);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [isAnnouncements, setIsAnnouncements] = useState(
    location.pathname === '/ads' || location.pathname.includes('/tag-ads/'),
  );

  const myRef = useRef<any>();

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getSpecificTypes());
    dispatch(getUsers({}));
    dispatch(getPricesTypes());
    dispatch(getCounty({ parent: 0 }));
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFormData(filtersForm || {});
  }, [filtersForm]);

  useEffect(() => {
    if (!formData) return;

    let town = filters?.find(filter => filter.propertyTypeView === 'TOWN')?.id;
    let street = filters?.find(
      filter => filter.propertyTypeView === 'STREET',
    )?.id;
    let zona = filters?.find(filter => filter.propertyTypeView === 'ZONE')?.id;

    if (countyId && town !== undefined) {
      dispatch(getLocalities({ parent: countyId })).then(res => {
        if (parseValues(res.payload)?.length > 0) {
          let clearObject = {};
          if (town)
            clearObject = {
              ...clearObject,
              [town]: parseValues(res.payload)[0],
            };
          if (street) clearObject = { ...clearObject, [street]: [] };
          if (zona) clearObject = { ...clearObject, [zona]: [] };
          clearObject && setFormData({ ...formData, ...clearObject });
          setTownId(parseValues(res.payload)[0].value);
        }
      });
    } else {
      dispatch(clearTowns());
      dispatch(clearStreets());
      dispatch(clearZona());
      let clearObject = {};
      if (town) clearObject = { ...clearObject, [town]: null };
      if (street) clearObject = { ...clearObject, [street]: [] };
      if (zona) clearObject = { ...clearObject, [zona]: [] };
      clearObject && setFormData({ ...formData, ...clearObject });

      setTownId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countyId]);

  useEffect(() => {
    if (!formData) return;

    let street = filters?.find(
      filter => filter.propertyTypeView === 'STREET',
    )?.id;
    let zona = filters?.find(filter => filter.propertyTypeView === 'ZONE')?.id;
    if (townId && zona !== undefined) {
      dispatch(getZona({ parent: townId }));
    }
    if (townId && street !== undefined) {
      dispatch(getStreet({ town: townId }));

      let clearObject = {};

      if (street) clearObject = { ...clearObject, [street]: [] };
      if (zona) clearObject = { ...clearObject, [zona]: [] };
      clearObject && setFormData({ ...formData, ...clearObject });
    } else {
      dispatch(clearStreets());
      dispatch(clearZona());
    }
    let clearObject = {};
    if (street) clearObject = { ...clearObject, [street]: [] };
    if (zona) clearObject = { ...clearObject, [zona]: [] };
    clearObject && setFormData({ ...formData, ...clearObject });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [townId]);

  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current?.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  const handleSelect = (id: number, ranges: any) => {
    setFormData({
      ...formData,
      [id]: {
        ...formData[id],
        from: moment(ranges.selection?.startDate).format('YYYY-MM-DD'),
        to:
          moment(ranges.selection?.endDate).format('YYYY-MM-DD') + ' 23:59:59',
      },
    });
  };

  const handleChange = (
    key: string | number,
    e: string | number | SelectValue | boolean | null | string[],
  ) => {
    setFormData({ ...formData, [key]: e });
  };

  const handleChangeRange = (
    key: string | number,
    object: string,
    e: string | number | boolean | SelectValue,
  ) => {
    setFormData({ ...formData, [key]: { ...formData[key], [object]: e } });
  };

  const resetFilters = (e: FormEvent) => {
    e.preventDefault();

    let initialForm = {};

    filters?.forEach(filter => {
      if (
        filter.propertyTypeView === 'RANGE' ||
        filter.propertyTypeView === 'RANGE_SELECT' ||
        filter.propertyTypeView === 'PRICE'
      ) {
        initialForm = { ...initialForm, [filter.id]: { from: '', to: '' } };
      } else if (
        filter.propertyTypeView === 'SELECT_OPTIONS' ||
        filter.propertyTypeView === 'LOCATION' ||
        filter.propertyTypeView === 'STREET' ||
        filter.propertyTypeView === 'SPECIFIC_TYPE' ||
        filter.propertyTypeView === 'ZONE' ||
        filter.propertyTypeView === 'OFFER_TYPE'
      ) {
        initialForm = { ...initialForm, [filter.id]: [] };
      } else {
        initialForm = { ...initialForm, [filter.id]: null };
      }
    });

    setFormData(initialForm);
    setFiltersForm && setFiltersForm(null);
  };

  const submitFilters = (e: FormEvent) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    let currentObject: any[] = [
      {
        category: activeCategory,
      },
    ];
    filters?.forEach(filter => {
      if (
        formData[filter.id] &&
        formData[filter.id] !== null &&
        formData[filter.id] !== ''
      ) {
        if (
          filter.propertyTypeView === 'RANGE' ||
          filter.propertyTypeView === 'PRICE'
        ) {
          if (
            formData[filter.id]?.from !== '' &&
            formData[filter.id]?.from !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              from: formData[filter.id]?.from,
            };
          if (
            formData[filter.id]?.to !== '' &&
            formData[filter.id]?.to !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              to: formData[filter.id]?.to,
            };
        }
        if (filter.propertyTypeView === 'RANGE_SELECT') {
          if (
            formData[filter.id]?.from?.value &&
            formData[filter.id]?.from?.value !== '' &&
            formData[filter.id]?.from?.value !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              from: formData[filter.id]?.from?.value,
            };
          if (
            formData[filter.id]?.to?.value &&
            formData[filter.id]?.to?.value !== '' &&
            formData[filter.id]?.to?.value !== null
          )
            currentObject[filter.id] = {
              ...currentObject[filter.id],
              to: formData[filter.id]?.to?.value,
            };
        } else if (
          filter.propertyTypeView === 'COUNTY' ||
          filter.propertyTypeView === 'TOWN'
        ) {
          if (formData[filter.id]?.value)
            currentObject[filter.id] = formData[filter.id]?.value;
        } else if (filter.propertyTypeView === 'SELECT') {
          currentObject[filter.id] = formData[filter.id]?.value;
        } else if (filter.propertyTypeView === 'EMPLOYEE') {
          if (formData[filter.id]?.value?.value)
            currentObject[filter.id] = {
              id: formData[filter.id]?.value?.value,
              associated: formData[filter.id]?.associated,
            };
        } else if (
          filter.propertyTypeView === 'SELECT_OPTIONS' ||
          filter.propertyTypeView === 'LOCATION' ||
          filter.propertyTypeView === 'STREET' ||
          filter.propertyTypeView === 'SPECIFIC_TYPE' ||
          filter.propertyTypeView === 'ZONE' ||
          filter.propertyTypeView === 'OFFER_TYPE'
        ) {
          if (formData[filter.id]?.length > 0)
            formData[filter.id].forEach((value: number) => {
              currentObject[filter.id] =
                currentObject[filter.id]?.length > 0
                  ? [...currentObject[filter.id], value]
                  : [value];
            });
        } else {
          currentObject[filter.id] = formData[filter.id];
        }
      }
    });

    currentObject?.length > 0 &&
      setFiltersForm &&
      setFiltersForm(currentObject);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const onSelectMobileFilter = (e: SelectValue) => {
    if (e.value === (filterByLettersOnMobile ? '1' : '0')) {
      setFilterByLettersOnMobile && setFilterByLettersOnMobile(e.value === '0');
      setFormData({});
      setCheckedAll && setCheckedAll(false);
    }
  };

  const options = [
    { value: '0', label: 'Filtrare dupa litere' },
    { value: '1', label: 'Filtrare dupa campuri' },
  ];

  const filterByGroup = (filter: Filter, position: Number) => {
    if (isAnnouncements) {
      return filter.adGroup === position;
    } else {
      return filter.propertyGroup === position;
    }
  };

  const renderContent = (filter: Filter) => {
    switch (filter.propertyTypeView) {
      case 'SELECT_OPTIONS':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={filter.values && parseValues(filter.values)}
          />
        );
      case 'OFFER_TYPE':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={
              pricesTypes &&
              parseValues(pricesTypes).map(option => {
                return {
                  value: option.value,
                  label:
                    option.label === 'SALE'
                      ? 'Vanzare'
                      : option.label === 'RENT_MONTH'
                        ? 'Inchiriere'
                        : 'Regim hotelier',
                };
              })
            }
          />
        );
      case 'SELECT':
        return (
          <FormSelect
            value={formData?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={filter.values && parseValues(filter.values)}
          />
        );
      case 'LOCATION':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={locations && parseValues(locations)}
          />
        );
      case 'COUNTY':
        return (
          <FormSelect
            value={formData?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => {
              handleChange(filter.id, e);
              setCountyId(e?.value ?? null);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={county && parseValues(county)}
          />
        );
      case 'TOWN':
        return (
          <FormSelect
            value={formData?.[filter.id] ?? ''}
            onChange={(e: SelectValue) => {
              handleChange(filter.id, e);
              setTownId(e?.value ?? null);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            key={filter.id}
            options={localities && parseValues(localities)}
          />
        );
      case 'STREET':
        return (
          <MultiselectCheckboxes
            onChange={(e: string[]) => {
              handleChange(filter.id, e);
            }}
            isClearable={true}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={streets && parseValues(streets)}
          />
        );
      case 'ZONE':
        return (
          <MultiselectCheckboxes
            onChange={(e: string[]) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            isClearable={true}
            options={zona && parseValues(zona)}
          />
        );
      case 'SELECT_OPTIONS_ROOMS':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            isClearable={true}
            options={roomsData}
          />
        );
      case 'SPECIFIC_TYPE':
        return (
          <MultiselectCheckboxes
            onChange={(e: SelectValue) => handleChange(filter.id, e)}
            labelText={true}
            inlineLabel={filter.title}
            value={formData?.[filter.id]}
            key={filter.id}
            options={specificTypes && parseValues(specificTypes)}
          />
        );
      case 'EMPLOYEE':
        return (
          <div className='form-group flex-input-column ' key={filter.id}>
            <FormSelect
              value={formData?.[filter.id]?.value ?? ''}
              onChange={(e: SelectValue) =>
                handleChangeRange(filter.id, 'value', e)
              }
              isClearable={true}
              labelText={true}
              inlineLabel={filter.title}
              options={users && parseValues(users)}
            />
            <Form.Group id='formGridCheckbox' key={filter.id}>
              <Form.Check
                type='checkbox'
                label={'Agent asociat'}
                checked={
                  formData?.[filter.id]?.associated === 1 ||
                  formData?.[filter.id]?.associated === true
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'associated', e.target.checked)
                }
              />
            </Form.Group>
          </div>
        );
      case 'CONTACT_DATA_SELECT':
        return (
          <div className='form-group flex-input-column ' key={filter.id}>
            <FormSelect
              value={formData?.[filter.id] ?? ''}
              onChange={(e: SelectValue) => handleChange(filter.id, e)}
              isClearable={true}
              labelText={true}
              inlineLabel={filter.title}
              options={contactData}
            />
          </div>
        );
      case 'INPUT':
      case 'OWNER_PHONE':
      case 'ID':
      case 'STREET_NUMBER':
      case 'KEYWORDS':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <Input
                labelText={false}
                value={formData?.[filter.id] || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(filter.id, e.target.value)
                }
              />
            </div>
          </div>
        );
      case 'RANGE':
      case 'PRICE':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <Input
                labelText={false}
                placeholder='De la'
                type='number'
                value={formData?.[filter.id]?.from || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'from', e.target.value)
                }
              />
              <Input
                labelText={false}
                placeholder='Pana la'
                type='number'
                value={formData?.[filter.id]?.to || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeRange(filter.id, 'to', e.target.value)
                }
              />
            </div>
          </div>
        );
      case 'RANGE_SELECT':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input'>
              <FormSelect
                labelText={false}
                options={filter.values && parseValues(filter.values)}
                isClearable={true}
                value={formData?.[filter.id]?.from}
                onChange={(e: SelectValue) =>
                  handleChangeRange(filter.id, 'from', e)
                }
              />
              <FormSelect
                labelText={false}
                options={filter.values && parseValues(filter.values)}
                isClearable={true}
                value={formData?.[filter.id]?.to}
                onChange={(e: SelectValue) =>
                  handleChangeRange(filter.id, 'to', e)
                }
              />
            </div>
          </div>
        );
      case 'RANGE_DATE':
        return (
          <div className='form-group' key={filter.id}>
            <label>{filter.title}</label>
            <div className='flex-input range-date date-column'>
              {showCalendar && (
                <div className='range-calendar' ref={myRef}>
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: formData?.[filter.id]?.from
                          ? new Date(formData?.[filter.id]?.from)
                          : undefined,
                        endDate: formData?.[filter.id]?.to
                          ? new Date(formData?.[filter.id]?.to)
                          : undefined,
                        key: 'selection',
                      },
                    ]}
                    locale={locale.enUS}
                    onChange={e => {
                      handleSelect(filter.id, e);
                    }}
                  />
                </div>
              )}
              <ReactDatePicker
                placeholderText={
                  formData?.[filter.id]?.from && formData?.[filter.id]?.to
                    ? `${moment(formData?.[filter.id]?.from).format('YYYY-MM-DD')} - ${moment(formData?.[filter.id]?.to).format('YYYY-MM-DD')}`
                    : 'De la/Pana la'
                }
                value={
                  formData?.[filter.id]?.from &&
                  formData?.[filter.id]?.to &&
                  !showCalendar
                    ? `${moment(formData?.[filter.id]?.from).format('YYYY-MM-DD')} - ${moment(formData?.[filter.id]?.to).format('YYYY-MM-DD')}`
                    : ''
                }
                selected={null}
                onCalendarOpen={() => setShowCalendar(true)}
                onChange={() => {}}
              />
            </div>
          </div>
        );
      case 'CHECKBOX':
      case 'VIRTUAL_TUR_CHECKBOX':
      case 'VIDEO_CHECKBOX':
      case 'FLOOR_EXCLUDED_NOT_SPECIFIC_CHECKBOX':
      case 'FLOOR_EXCLUDED_P_LAST_CHECKBOX':
      case 'HAS_CONTRACT_DATA_CHECKBOX':
      case 'EXCLUSIVENESS_CHECKBOX':
        return (
          <Form.Group id='formGridCheckbox' key={filter.id}>
            <Form.Check
              type='checkbox'
              label={filter.title}
              checked={
                formData?.[filter.id] === 1 || formData?.[filter.id] === true
                  ? formData?.[filter.id]
                  : false
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(filter.id, e.target.checked)
              }
            />
          </Form.Group>
        );
      default:
        return null;
    }
  };

  return (
    <div className='filter-form-container property-filters-container'>
      {!isAnnouncements && (
        <div className='form-item hide-on-desktop'>
          <FormSelect
            labelText={false}
            placeholder='Filtrare dupa'
            isSearchable={false}
            options={options}
            className='select-blue'
            onChange={onSelectMobileFilter}
            value={filterByLettersOnMobile ? options[0] : options[1]}
          />
        </div>
      )}
      {filterByLettersOnMobile && (
        <div className='alphabet-wrapper hide-on-desktop'>
          <AlphabetFilter
            filtersForm={{ letter: letterFilter }}
            setFiltersForm={(e: any) => {
              setLetterFilter && setLetterFilter(e.letter);
            }}
          />
        </div>
      )}
      {(!isMobile || !filterByLettersOnMobile) && (
        <>
          <div className='header-section display-flex'>
            <h5 className='title-section'>
              Filtru{' '}
              {
                categories.find(category => category.id === activeCategory)
                  ?.title
              }
            </h5>
            <div className='btns-section'>
              <button
                className='save-btn btn-squere-blue'
                aria-label='Salveaza'
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  setOpenedFilters && setOpenedFilters(!openedFilters);
                }}
              >
                <IconArrowUp className={openedFilters ? 'inverse' : ''} />
              </button>
            </div>
          </div>
          <div className='select-propreties'>
            <FormSelect
              value={
                activeCategory
                  ? parseValues(categories)?.find(
                      option => option.value === String(activeCategory),
                    )
                  : { value: 0, label: 'Toate' }
              }
              onChange={(e: SelectValue) => {
                setActiveCategory && setActiveCategory(Number(e.value));
              }}
              labelText={false}
              options={[
                { value: 0, label: 'Toate' },
                ...parseValues(categories),
              ]}
            />
          </div>
        </>
      )}
      {loadingFilters ? (
        <Spinner />
      ) : (
        <form>
          {(!isMobile || !filterByLettersOnMobile) && (
            <>
              <div
                className={`flex-rows ${openedFilters ? '' : 'short-filters'}`}
              >
                {filters && filters.length > 0 && (
                  <>
                    {!openedFilters &&
                      filters
                        .filter(filter => filterByGroup(filter, 1))
                        .slice(0, openedFilters ? filters.length : 5)
                        .map((filter: Filter) => renderContent(filter))}
                    <div className='filters-column'>
                      {openedFilters &&
                        filters
                          .filter(filter => filterByGroup(filter, 1))
                          .slice(0, openedFilters ? filters.length : 5)
                          .map((filter: Filter) => renderContent(filter))}
                    </div>
                    <div className='filters-column'>
                      {openedFilters &&
                        filters
                          .filter(filter => filterByGroup(filter, 2))
                          .map((filter: Filter) => renderContent(filter))}
                    </div>
                    <div className='filters-column'>
                      {openedFilters &&
                        filters
                          .filter(filter => filterByGroup(filter, 3))
                          .map((filter: Filter) => renderContent(filter))}
                    </div>
                    <div className='filters-column'>
                      {openedFilters &&
                        filters
                          .filter(filter => filterByGroup(filter, 4))
                          .map((filter: Filter) => renderContent(filter))}
                    </div>
                    <div className='filters-column'>
                      {openedFilters &&
                        filters
                          .filter(filter => filterByGroup(filter, 5))
                          .map((filter: Filter) => renderContent(filter))}
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          <div className='bottom-filter-section'>
            <div className='form-line-section'></div>
            <div className='form-btns-section'>
              <button className='reset-btn' onClick={e => resetFilters(e)}>
                Reseteaza{' '}
              </button>
              <button
                className='filter-btn btn-blue'
                onClick={e => submitFilters(e)}
              >
                {loading && <Spinner className='btn-blue-spinner' />}
                Filtreaza
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default FiltersForm;
