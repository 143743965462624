import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddContractTemplateForm from '../../components/AddContractTemplateForm';
import AddContractTemplateFormContact from '../../components/AddContractTemplateFormContact';
import Header from '../../components/Header';
import ModalPreviewTemplate from '../../components/ModalPreviewTemplate';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import {
  addContractTemplate,
  editContractTemplate,
  getContractTemplate,
} from '../../redux/contractTemplates/contractTemplatesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import useHelper from '../../helpers/useHelper';
import {
  ContractTemplateField,
  ContractTemplateForm,
} from '../../models/ContractTemplates';
import './AddContractTemplate.scss';

interface Props {}

const AddContractTemplate: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { diplayErrorMessages } = useHelper();
  const { id } = useParams();
  const { contractTemplate } = useAppSelector(state => state.contractTemplates);
  const [loading, setLoading] = useState(id ? true : false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [formData, setFormData] = useState<ContractTemplateForm>({
    title: '',
    content: '',
    additionalFields: [],
  });

  useEffect(() => {
    if (id) {
      dispatch(getContractTemplate(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    contractTemplate?.id &&
      id &&
      setFormData({
        title: contractTemplate?.title,
        content: contractTemplate?.content,
        additionalFields: contractTemplate?.fields,
      });
    // eslint-disable-next-line
  }, [contractTemplate]);

  useEffect(() => {
    if (id && formData?.title?.length > 0) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
    // eslint-disable-next-line
  }, [formData?.title]);

  const submitTemplate = async () => {
    try {
      const body = {
        ...formData,
        additionalFields: formData.additionalFields
          ? formData.additionalFields?.filter(
              (field: ContractTemplateField) => field.title !== '',
            )
          : [],
      };

      const response = await dispatch(
        id
          ? editContractTemplate({ body, id: +id })
          : addContractTemplate({
              body,
            }),
      );
      unwrapResult(response);

      if (response?.payload?.id) {
        toast.success('Sablonul a fost salvat cu success!');
        navigate('/contract-templates');
      }
    } catch (err: any) {
      diplayErrorMessages(err);
    }
  };

  const previewTemplate = () => {
    setShowConfirmModal(true);
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper add-contract-template-wrapper'>
        <Header
          title={id ? 'Editeaza Template Contract' : 'Adauga Template Contract'}
          subtitle=''
          isbutton={false}
          btnText='Add new contract'
        />
        <div className='display-flex add-contract-template-container'>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className='add-contract-form-md bg-white'>
                <AddContractTemplateForm
                  formData={formData}
                  setFormData={setFormData}
                  submitTemplate={submitTemplate}
                  previewTemplate={previewTemplate}
                />
              </div>
              <div className='add-contract-form-md bg-blue'>
                <h2 className='font-lg'>Campuri contract</h2>
                <AddContractTemplateFormContact
                  fields={
                    formData.additionalFields &&
                    formData.additionalFields?.length > 0
                      ? formData.additionalFields
                      : []
                  }
                  setFields={(e: ContractTemplateField[]) =>
                    setFormData({ ...formData, additionalFields: e })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
      {showConfirmModal && (
        <ModalPreviewTemplate
          showModal={showConfirmModal}
          item={id && contractTemplate ? contractTemplate : formData}
          hideModal={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default AddContractTemplate;
