import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContractTemplatesModal from '../../components/ContractTemplatesModal/ContractTemplatesModal';
import ContractsTemplatesListItem from '../../components/ContractsTemplatesListItem';
import FilterListContractsTemplates from '../../components/FilterListContractsTemplates';
import FilterListHeader from '../../components/FilterListHeader';
import FilterListHeaderContractTemplates from '../../components/FilterListHeaderContractTemplates';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import Pagination from '../../components/Pagination';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import {
  deleteContractTemplate,
  getContractTemplates,
} from '../../redux/contractTemplates/contractTemplatesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import useHelper from '../../helpers/useHelper';
import { ContractTemplateListItem } from '../../models/ContractTemplates';
import './ContractTemplates.scss';

interface Props {}

const ContractTemplates: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { diplayErrorMessages } = useHelper();
  const { contractTemplates, count, loadingTemplates } = useAppSelector(
    state => state.contractTemplates,
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(24);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [sort, setSort] = useState('');
  const [filtersForm, setFiltersForm] = useState({});
  const [prevState, setPrevState] = useState({
    page: 0,
    pageSize: 24,
    sort: '',
  });

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let newState = {
      page: page,
      pageSize: pageSize,
      sort: sort,
    };

    if (
      JSON.stringify(prevState.page) !== JSON.stringify(newState.page) ||
      JSON.stringify(prevState.pageSize) !==
        JSON.stringify(newState.pageSize) ||
      JSON.stringify(prevState.sort) !== JSON.stringify(newState.sort)
    ) {
      getLocalTemplates();
      setPrevState(newState);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, sort]);

  useEffect(() => {
    if (page === 0) {
      getLocalTemplates();
    } else {
      setPage(0);
    }

    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersForm]);

  const getLocalTemplates = async () => {
    let filters = {
      offset: page + 1,
      limit: pageSize,
      sort: sort,
      ...filtersForm,
    };
    const result = await dispatch(getContractTemplates(filters));
    unwrapResult(result);

    setLoadingBtn(false);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected);
  };

  const deleteTemplate = async (template: ContractTemplateListItem) => {
    try {
      const response = await dispatch(deleteContractTemplate(template.id));
      unwrapResult(response);

      getLocalTemplates();
    } catch (err) {
      diplayErrorMessages(err);
    }
  };

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };
  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };
  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper contracts-templates-wrapper'>
        <Header
          title='Template-uri Contracte'
          subtitle=''
          btnEvent={() => navigate('/add-contract-template')}
          isbutton={true}
          btnText='Adauga Template'
        />
        {!isMobile && (
          <FilterListContractsTemplates
            filtersForm={filtersForm}
            setFiltersForm={setFiltersForm}
            loadingBtn={loadingBtn}
            setLoadingBtn={setLoadingBtn}
          />
        )}

        <FilterListHeader
          pageCount={Math.ceil(count / pageSize)}
          currentPage={page}
          itemsPerPage={pageSize}
          totalCount={count}
          count={contractTemplates?.length}
          setItemsPerPage={e => setPageSize(e)}
          handlePageClick={handlePageClick}
          showActions={false}
        />

        <FilterListHeaderContractTemplates
          filtersForm={filtersForm}
          setFiltersForm={setFiltersForm}
          sortItems={setSort}
          sort={sort}
        />

        {loadingTemplates ? (
          <div className='container'>
            <Spinner />
          </div>
        ) : (
          <>
            <div className='list-items-container'>
              {contractTemplates.map(template => (
                <ContractsTemplatesListItem
                  key={template.id}
                  template={template}
                  onRemoveTemplate={() => deleteTemplate(template)}
                />
              ))}
              {contractTemplates?.length === 0 && (
                <p className='empty-text'>Cautarea ta a returnat 0 Rezultate</p>
              )}
            </div>
            <Pagination
              isSelectPage={false}
              currentPage={page}
              itemsPerPage={pageSize}
              pageCount={Math.ceil(count / pageSize)}
              handlePageClick={handlePageClick}
              setItemsPerPage={e => setPageSize(e)}
            />
          </>
        )}
        <FixedActions
          disabledActions={true}
          ModalAction={ModalAction}
          ModalFilter={ModalFilter}
          ModalSearch={ModalSearch}
        />

        <ContractTemplatesModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          isAction={isAction}
          isSearch={isSearch}
          isFilter={isFilter}
          filtersForm={filtersForm}
          setFiltersForm={setFiltersForm}
        />
      </div>
    </div>
  );
};

export default ContractTemplates;
