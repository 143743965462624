import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ContractModal from '../../components/ContractModal';
import ContractsListItem from '../../components/ContractsListItem';
import FilterListContracts from '../../components/FilterListContracts';
import FilterListHeader from '../../components/FilterListHeader';
import FilterListHeaderContracts from '../../components/FilterListHeaderContracts';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import ModalConfirm from '../../components/ModalConfirm';
import Pagination from '../../components/Pagination';
import SideNav from '../../components/SideNav';
import { checkAll, toggleChecked } from '../../redux/contracts/contracts-slice';
import {
  exportContracts,
  getContracts,
  getContractsTemplates,
  removeContracts,
} from '../../redux/contracts/contractsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUsers } from '../../redux/users/usersThunk';
import useHelper from '../../helpers/useHelper';
import { Contract } from '../../models/Contract';
import './Contracts.scss';

interface Props {}

const actionOptions = [
  {
    value: 'edit',
    label: 'Modifica',
  },
  {
    value: 'export-pdf',
    label: 'Exporta PDF',
  },
  {
    value: 'export-xlsx',
    label: 'Exporta XLSX',
  },
  {
    value: 'remove',
    label: 'Sterge',
  },
];

const Contracts: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { diplayErrorMessages } = useHelper();

  const { count, contracts } = useAppSelector(state => state.contracts);
  const [sort, setSort] = useState('');
  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(24);
  const [filtersForm, setFiltersForm] = useState({ q: '' });
  const [prevState, setPrevState] = useState({
    page: 0,
    pageSize: 24,
    sort: '',
    filtersForm: { q: '' },
  });
  const [pageCount, setPageCount] = useState(Math.ceil(count / pageSize));
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleConfirmModal, setTitleConfirmModal] = useState('');
  const [bodyConfirmModal, setBodyConfirmModal] = useState('');
  const initialized = useRef(false);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  useEffect(() => {
    if (count) {
      const pageCount = Math.ceil(count / pageSize);

      setPageCount(pageCount);
    }
  }, [count, pageSize]);

  useEffect(() => {
    dispatch(getContractsTemplates({}));
    dispatch(getUsers({}));
    if (!initialized.current) {
      initialized.current = true;
      getLocalContracts();
    }
    // eslint-disable-next-line
  }, []);

  const handlePageClick = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected);
  };

  const editItems = () => {
    if (contracts?.some((contract: Contract) => contract.checked)) {
      navigate(
        `/edit-contract/${contracts?.filter((contract: Contract) => contract.checked)[0].id}`,
      );
    } else {
      toast.error('Alegeti un contract!');
    }
  };

  const exportPdfItems = () => {
    if (contracts?.some((contract: Contract) => contract.checked)) {
      exportItems('ZIP');
    } else {
      toast.error('Alegeti un contract!');
    }
  };

  const exportXlsxItems = () => {
    if (contracts?.some((contract: Contract) => contract.checked)) {
      exportItems('XLSX');
    } else {
      toast.error('Alegeti un contract!');
    }
  };

  const handleCheckboxChange = (id?: number, all?: boolean): void => {
    if (id) {
      const contract = contracts?.find(
        (contract: Contract) => contract.id === id,
      );

      if (contract && contract.checked && isCheckedAll) {
        setCheckedAll(false);
      }

      dispatch(toggleChecked(id));
    }

    if (all) {
      dispatch(checkAll(true));
    }
  };

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };

  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };

  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    let newState = {
      page: page,
      pageSize: pageSize,
      sort: sort,
      filtersForm: filtersForm,
    };

    if (
      JSON.stringify(prevState.page) !== JSON.stringify(newState.page) ||
      JSON.stringify(prevState.sort) !== JSON.stringify(newState.sort) ||
      JSON.stringify(prevState.pageSize) !== JSON.stringify(newState.pageSize)
    ) {
      setCheckedAll(false);
      getLocalContracts();
      setPrevState(newState);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, sort]);

  useEffect(() => {
    let newState = {
      page: page,
      pageSize: pageSize,
      sort: sort,

      filtersForm: filtersForm,
    };
    if (
      JSON.stringify(prevState.filtersForm) !==
      JSON.stringify(newState.filtersForm)
    ) {
      isCheckedAll && setCheckedAll(false);
      showCheckedItems && setShowCheckedItems(false);
      if (page > 0) {
        setPage(0);
      } else {
        getLocalContracts();
        setPrevState(newState);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filtersForm)]);

  const getLocalContracts = () => {
    dispatch(
      getContracts({
        limit: pageSize,
        offset: page + 1,
        ...(sort ? { sort: sort } : {}),
        ...filtersForm,
      }),
    );
  };

  const openConfirmModal = (titleAction: string, bodyAction: string) => {
    if (contracts.some((contract: Contract) => contract.checked)) {
      setTitleConfirmModal(`${titleAction} contracte`);
      setBodyConfirmModal(
        `Esti sigur ca doresti sa ${bodyAction} contractele selectate?`,
      );
      setShowConfirmModal(true);
    } else {
      toast.error('Alegeti cel putin un contract!');
    }
  };

  const removeConfirmedItems = async () => {
    try {
      const response = await dispatch(
        removeContracts(
          contracts
            .filter((contract: Contract) => contract.checked)
            .map((contract: Contract) => contract.id)
            .join(','),
        ),
      );
      unwrapResult(response);

      if (response.type === 'crm/removeContracts/fulfilled') {
        toast.success('Contractele selectate au fost sterse!');
        dispatch(checkAll(false));
        closeModal();
        getLocalContracts();
      }
    } catch (err) {
      diplayErrorMessages(err);
    }
  };

  const exportItems = async (type: string) => {
    try {
      const response = await dispatch(
        exportContracts({
          ids: contracts
            .filter((contract: Contract) => contract.checked)
            .map((contract: Contract) => contract.id),
          type: type,
        }),
      );
      unwrapResult(response);

      if (response.payload?.downloadUrl) {
        const fileUrl = `${process.env.REACT_APP_API_URL}${response.payload?.downloadUrl}`;

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = ''; // Optional: You can set a custom filename if needed
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.success('Contractele selectate au fost exportate!');
        dispatch(checkAll(false));
      }
    } catch (err) {
      diplayErrorMessages(err);
    }
  };

  const confirmActionWithSelectedItems = () => {
    if (titleConfirmModal === 'Stergere contracte') {
      removeConfirmedItems();
    }
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper contracts-wrapper'>
        <Header
          title='Contracte'
          subtitle=''
          btnEvent={() => navigate('/add-contract')}
          isbutton={true}
          breadcrumbs={[
            { link: '/', name: 'Home' },
            { link: '/contracts', name: 'Contracte' },
          ]}
          btnText='Add new contract'
        />
        {!isMobile && (
          <FilterListContracts
            filtersForm={filtersForm}
            setFiltersForm={setFiltersForm}
            isCheckedAll={isCheckedAll}
            setCheckedAll={setCheckedAll}
          />
        )}

        <FilterListHeader
          pageCount={pageCount}
          currentPage={page}
          itemsPerPage={pageSize}
          totalCount={count}
          count={contracts?.length}
          showCheckedItems={showCheckedItems}
          checkedItemsLength={
            contracts?.filter((contract: Contract) => contract.checked).length
          }
          setShowCheckedItems={setShowCheckedItems}
          setItemsPerPage={setPageSize}
          handlePageClick={handlePageClick}
          editItems={editItems}
          exportPdfItems={exportPdfItems}
          exportXlsxItems={exportXlsxItems}
          removeItems={() => openConfirmModal('Stergere', 'stergi')}
          options={actionOptions}
        />
        <FilterListHeaderContracts
          setCheckedAll={(e: boolean) => {
            setCheckedAll(e);

            if (e) {
              handleCheckboxChange(undefined, true);
            } else {
              dispatch(checkAll(false));
            }
          }}
          filtersForm={filtersForm}
          setFiltersForm={setFiltersForm}
          isCheckedAll={isCheckedAll}
          sortItems={setSort}
          sort={sort}
        />

        {/* <FilterParticularRequestList /> */}
        <div className='list-items-container'>
          {contracts?.map((contract: Contract) => (
            <ContractsListItem
              item={contract}
              key={'contract' + contract.id}
              checked={contract.checked}
              handleCheckboxChange={handleCheckboxChange}
            />
          ))}
        </div>
        {!showCheckedItems && pageCount > 1 && (
          <Pagination
            isSelectPage={false}
            currentPage={page}
            itemsPerPage={pageSize}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            setItemsPerPage={setPageSize}
          />
        )}
        <FixedActions
          disabledActions={contracts?.every(
            (contract: Contract) => !contract.checked,
          )}
          ModalAction={ModalAction}
          ModalFilter={ModalFilter}
          ModalSearch={ModalSearch}
        />
        <ContractModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          isAction={isAction}
          isSearch={isSearch}
          isFilter={isFilter}
          filtersForm={filtersForm}
          setFiltersForm={setFiltersForm}
          setCheckedAll={(e: boolean) => {
            setCheckedAll(e);
            if (e) {
              handleCheckboxChange(undefined, true);
            } else {
              dispatch(checkAll(false));
            }
          }}
          isCheckedAll={isCheckedAll}
          editItems={editItems}
        />
      </div>
      <ModalConfirm
        showModal={showConfirmModal}
        title={titleConfirmModal}
        body={bodyConfirmModal}
        onConfirmed={confirmActionWithSelectedItems}
        hideModal={() => setShowConfirmModal(false)}
      />
    </div>
  );
};

export default Contracts;
