import { LatLngLiteral, divIcon } from 'leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Circle,
  MapContainer,
  Marker,
  Pane,
  Popup,
  TileLayer,
  ZoomControl,
  useMap,
  useMapEvents,
} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import RadiusSelect from '../../components/RadiusSelect';
import {
  setStateCenter,
  setStateRadius,
} from '../../redux/announces/announces-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getFilePath } from '../../helpers/useHelper';
import { AnnounceMap, hasValue } from '../../models/Announces';
import { SelectValue } from '../../models/Properties';
import ListItemImg from '../../assets/img/house_square.png';
import { getPriceType } from './MapPage';

// import marker from '../../assets/img/pin.svg';

interface Props {
  activeCategory: number;
  filtersForm: any;
}

function transformPrice(price: string) {
  const numPrice = parseFloat(price);

  if (isNaN(numPrice)) {
    return 'Invalid price';
  }

  if (numPrice >= 1000) {
    const dividedPrice = numPrice / 1000;
    return dividedPrice % 1 === 0
      ? `${dividedPrice}K`
      : `${dividedPrice.toFixed(1)}K`;
  }
  return numPrice.toString();
}

function getCurrencySymbol(currencyName: string) {
  const currencySymbols: Record<string, string> = {
    Dollar: '$',
    Euro: '€',
    Pound: '£',
    Yen: '¥',
    Ruble: '₽',
    Rupee: '₹',
    Yuan: '¥',
    AustralianDollar: 'A$',
    CanadianDollar: 'C$',
  };

  return currencySymbols[currencyName] || 'Unknown currency';
}

const radiusList = [
  { value: 20, label: '20 km' },
  { value: 30, label: '30 km' },
  { value: 40, label: '40 km' },
  { value: 50, label: '50 km' },
  { value: 100, label: '100 km' },
  { value: 150, label: '150 km' },
];

// NOTE: iconCreateFunction is running by leaflet, which is not support ES6 arrow func syntax
// eslint-disable-next-line
const createClusterCustomIcon = function (cluster: any) {
  return L.divIcon({
    html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
    className: 'custom-marker-cluster',
    iconSize: L.point(33, 33, true),
  });
};

const Map: React.FC<Props> = ({ activeCategory, filtersForm }) => {
  const dispatch = useAppDispatch();

  const { announcesMap, stateCountyId, stateTownId } = useAppSelector(
    state => state.announces,
  );
  const [center, setCenter] = useState<LatLngLiteral>({
    lat: announcesMap[0]?.latitude
      ? Number(announcesMap[0]?.latitude)
      : 44.43225,
    lng: announcesMap[0]?.longitude
      ? Number(announcesMap[0]?.longitude)
      : 26.10626,
  });
  const [activeMarker, setActiveMarker] = useState<number | null>(null);
  const [radius, setRadius] = useState(30);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const { localities, county } = useAppSelector(state => state.localities);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setStateCenter(center));
    dispatch(setStateRadius(radius));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center, radius]);

  useEffect(() => {
    if (stateTownId) {
      const town = localities.filter(
        locality => locality.id === +stateTownId,
      )[0];
      town.latitude &&
        town.longitude &&
        setCenter({
          lat: +town.latitude,
          lng: +town.longitude,
        });
    } else {
      if (stateCountyId) {
        const countyObject = county.filter(
          countyItem => countyItem.id === +stateCountyId,
        )[0];
        countyObject.latitude &&
          countyObject.longitude &&
          setCenter({
            lat: +countyObject.latitude,
            lng: +countyObject.longitude,
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCountyId, stateTownId]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const MapRecenter = () => {
    const map = useMap();

    useEffect(() => {
      map.setView(center, map.getZoom());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (map && center && radius) {
        try {
          const circleBounds = L.latLng(center).toBounds(radius * 1500);

          map.fitBounds(circleBounds, { padding: [20, 20] });
        } catch (error) {
          console.error('Error adjusting map bounds:', error);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [radius]);

    return null;
  };

  const MapEvents = () => {
    const map = useMap();

    useMapEvents({
      click(e: any) {
        if (e.latlng) {
          setCenter(e.latlng);
          map.setView(e.latlng, map.getZoom());
        }
      },
      autopanstart(e: any) {
        // Ensure to stop propagation using e.originalEvent
        if (e.originalEvent) {
          e.originalEvent.stopPropagation();
          e.originalEvent.preventDefault();
        }
      },
    });
    return false;
  };

  const pointHtml = useCallback(
    (point: AnnounceMap) => `
  <div class='point ${activeMarker === point.id ? ' active' : ''}'>${point?.currencySymbol?.length < 2 ? point?.currencySymbol : getCurrencySymbol(point?.currencySymbol)}  ${transformPrice(point.price)}</div>
  `,
    [activeMarker],
  );

  const circle = useMemo(
    () => (
      <Circle
        center={center}
        radius={Number(radius * 1000)}
        pathOptions={{
          fill: true,
          fillColor: '#425B75',
          fillOpacity: 0.12,
          color: '#425B75',
          stroke: true,
        }}
      />
    ),
    [center, radius],
  );

  return (
    <>
      <div className={`radius-dropdown`}>
        <RadiusSelect
          className={'radius-dropdown__value'}
          options={radiusList}
          value={
            radiusList?.filter(radiusItem => radiusItem?.value === radius)[0]
          }
          onChange={(e: SelectValue) => {
            setRadius && setRadius(Number(e.value));
          }}
        />
      </div>
      <MapContainer
        zoom={9}
        scrollWheelZoom={false}
        center={center}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <ZoomControl position='topright'></ZoomControl>
        <MapEvents />
        <MapRecenter />
        <MarkerClusterGroup
          chunkedLoading
          iconCreateFunction={createClusterCustomIcon}
          showCoverageOnHover={false}
          maxClusterRadius={150}
          spiderfyOnMaxZoom={true}
          polygonOptions={{
            fillColor: '#1d195640',
            color: '#425b75',
            weight: 2,
            opacity: 1,
            fillOpacity: 0.8,
          }}
        >
          {announcesMap?.map((point: AnnounceMap) => (
            <Marker
              key={point.id}
              position={{
                lat: Number(point.latitude),
                lng: Number(point.longitude),
              }}
              icon={divIcon({
                html: pointHtml(point),
              })}
              eventHandlers={{
                click: (event: any) => {
                  console.log('marker click', event);

                  event.originalEvent.stopPropagation();
                  setActiveMarker(point?.id);
                },

                popupclose: () => setActiveMarker(null),
              }}
            >
              <Popup>
                <div className='point__item'>
                  <div className='title-text'>
                    {getPriceType(point)} {point?.categoryTitle} zona{' '}
                    {point?.zoneLabel},{' '}
                  </div>
                  <div className='img-container'>
                    <img
                      src={
                        point.mediaPath
                          ? getFilePath(point.mediaPath)
                          : ListItemImg
                      }
                      alt='img-item'
                    />
                  </div>
                  <div className='details-container'>
                    <div className='item-info-tags'>
                      {hasValue(point.usableSurface) && (
                        <span className='label-item-tag'>
                          SU: {point.usableSurface}
                        </span>
                      )}
                      {hasValue(point.constructionYear) && (
                        <span className='label-item-tag'>
                          AN: {point.constructionYear}
                        </span>
                      )}
                      {hasValue(point.floorNumber) && (
                        <span className='label-item-tag'>
                          Etaj: {point.floorNumber}
                        </span>
                      )}
                      {hasValue(point.comfort) && (
                        <span className='label-item-tag'>
                          Confort: {point.comfort}
                        </span>
                      )}
                      {hasValue(point.floors) && (
                        <span className='label-item-tag'>
                          Nr etaje: {point.floors}
                        </span>
                      )}
                      {hasValue(point.numberOfRooms) && (
                        <span className='label-item-tag'>
                          Nr camere: {point.numberOfRooms}
                        </span>
                      )}
                      {hasValue(point.phones) &&
                        point.phones.map((phone: any) => (
                          <span className='label-item-tag' key={phone}>
                            {phone}
                          </span>
                        ))}
                    </div>
                    <div className='price-text'>
                      {point?.currencySymbol?.length < 2
                        ? point?.currencySymbol
                        : getCurrencySymbol(point?.currencySymbol)}{' '}
                      {Number(point.price) % 1 === 0
                        ? Number(point.price)
                        : Number(point.price).toFixed(1)}{' '}
                    </div>{' '}
                  </div>
                </div>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
        {!isMobile && (
          <Pane name='yellow-rectangle' style={{ zIndex: 499 }}>
            {circle}
          </Pane>
        )}
      </MapContainer>
    </>
  );
};

export default Map;
