import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AlertAddModal from '../../components/AlertAddModal';
import AlocatedSlots from '../../components/AlocatedSlots';
import DropdownBrands from '../../components/DropdownBrands';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import NotificationsCards from '../../components/NotificationsCards';
import SideNav from '../../components/SideNav';
import SwiperDashboard from '../../components/SwiperDashboard';
import { getActiveAgents } from '../../redux/dashboard/dashboardThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import IconExternalLink from '../../icons/IconExternalLink';
import IconRingBell from '../../icons/IconRingBell';
import './Home.scss';

interface Props {}

const Home: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { auth } = useAppSelector(state => state.auth);
  // eslint-disable-next-line
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.Dashboard),
  );
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(getActiveAgents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='flex-container home-page display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title={`Good morning, ${auth?.profile?.fullName}!`}
          subtitle=''
          isbutton={false}
          btnText=''
        />
        {havePermission ? (
          <>
            <NotificationsCards />
            <div className='display-flex table-swiper'>
              <AlocatedSlots />
              {/* <SwiperDashboard /> */}
            </div>
          </>
        ) : (
          <MissingAccess />
        )}
        <button
          className='btn-white btn-fixed-right'
          onClick={() => {
            setIsModalOpen(true);
          }}
          title='3 anunturi noi in baza alertelor'
        >
          <span className='btn-label'>
            <span className='ring-bell'>
              <IconRingBell aria-hidden='true' />
            </span>
            <span>
              <strong>3 anunturi noi </strong> in baza alertelor
            </span>
          </span>

          <span className='btn-squere-blue' aria-hidden='true'>
            <IconExternalLink aria-hidden='true' />
          </span>
        </button>
        <AlertAddModal isModalOpen={isModalOpen} closeModal={closeModal} />
      </div>
    </div>
  );
};

export default Home;
