import React, { useState } from 'react';
import FilterListNewEvaluation from '../../components/FilterListNewEvaluation';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import NewEvaluationModal from '../../components/NewEvaluationModal';
import PriceEvaluationGrafic from '../../components/PriceEvaluationGrafic';
import SideNav from '../../components/SideNav';
import './NewEvaluation.scss';

interface Props {}

const NewEvaluation: React.FC<Props> = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };
  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };
  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper new-evaluation-wrapper'>
        <Header
          title='Evaluare pret'
          subtitle=''
          // btnEvent={() => navigate('/add-contact')}
          isbutton={true}
          btnText='Evaluare Nou'
          breadcrumbs={[
            { link: '/', name: 'Home' },
            { link: '/price-evaluation', name: 'Evaluare pret' },
            { link: '', name: 'Evaluare noua' },
          ]}
        />
        <div className='bg-white new-evaluation-container'>
          {!isMobile && <FilterListNewEvaluation />}

          <PriceEvaluationGrafic />
          <div className='buttons-bottom display-flex'>
            <button className='button button-border'>Inchide</button>
            <button className='button button-blue'>Salveaza</button>
          </div>
        </div>

        <FixedActions
          ModalAction={ModalAction}
          ModalFilter={ModalFilter}
          ModalSearch={ModalSearch}
        />
        <NewEvaluationModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          isAction={isAction}
          isSearch={isSearch}
          isFilter={isFilter}
        />
      </div>
    </div>
  );
};

export default NewEvaluation;
