import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormSelect from '../../components/FormSelect';
import Gallery from '../../components/Gallery';
import Header from '../../components/Header';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import {
  getAnnounces,
  getAnnouncesFilters,
  getMapAnnounces,
} from '../../redux/announces/announcesThunk';
import { getCategories } from '../../redux/categories/categoriesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getCounty,
  getLocations,
  getSpecificTypes,
} from '../../redux/localities/localitiesThunk';
import { getPricesTypes } from '../../redux/properties/propertiesThunk';
import { getUsers } from '../../redux/users/usersThunk';
import { getFilePath } from '../../helpers/useHelper';
import { Announce, hasValue } from '../../models/Announces';
import { Category } from '../../models/Categories';
import { Locality } from '../../models/Localities';
import { Price, SelectValue } from '../../models/Properties';
import ListItemImg from '../../assets/img/house_square.png';
import CloseIcon from '../../assets/svg/closemodal.svg';
import IconArrowDownSmall from '../../icons/IconArrowDownSmall';
import IconArrowUp from '../../icons/IconArrowUp';
import IconClose from '../../icons/IconClose';
import Map from './Map';
import MapFilters from './MapFilters';
import './MapPage.scss';

export const getPriceType = (announce: Announce) => {
  let name;

  announce?.prices?.forEach((price: Price) => {
    switch (price.id) {
      case 1:
        name = 'Vanzare';
        break;
      case 2:
        name = 'Inchiriere';
        break;
      case 3:
        name = 'Regim hotelier';
        break;
      case 4:
        name = 'Vanzare MP';
        break;
      case 5:
        name = 'Inchiriere MP';
        break;
      default:
        name = '';
    }
  });

  return name;
};

const sortBy = [
  { label: 'Pret ascendent', value: 'price' },
  { label: 'Pret descendent', value: '-price' },
];

const MapPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { announces, totalCount, centerMap, radius, stateCountyId, loading } =
    useAppSelector(state => state.announces);
  const { categories } = useAppSelector(state => state.categories);
  const { county } = useAppSelector(state => state.localities);

  const [openedFilters, setOpenedFilters] = useState(false);
  const [openedList, setOpenedList] = useState(false);
  const [openedItem, setOpenedItem] = useState<Announce | null>(null);
  const [sort, setSort] = useState<any>(sortBy[1]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [filtersForm, setFiltersForm] = useState<any>();
  const [activeCategory, setActiveCategory] = useState<number>(0);
  const [items, setItems] = useState<Announce[]>(announces ?? []); // State to store fetched items
  const activeCategoryRef = useRef(activeCategory);
  const filtersFormRef = useRef(filtersForm);
  const listContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getLocations());
    dispatch(getSpecificTypes());
    dispatch(getUsers({}));
    dispatch(getPricesTypes());
    dispatch(getCounty({ parent: 0 }));
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadAnnounces(null);

    filtersFormRef.current = filtersForm;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersForm]);

  useEffect(() => {
    activeCategory === 0
      ? dispatch(getAnnouncesFilters())
      : dispatch(getAnnouncesFilters(activeCategory));

    if (filtersForm) {
      setFiltersForm(undefined);
    }

    activeCategoryRef.current = activeCategory;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  useEffect(() => {
    loadMapAnnounces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersForm]);

  useEffect(() => {
    loadMapAnnounces();
    loadAnnounces(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, centerMap, radius]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const handleScroll = useCallback(() => {
    const container = listContainerRef.current;

    if (!container) return;

    if (
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 100 &&
      !loading &&
      items.length < +totalCount
    ) {
      loadAnnounces(items.length / 20 + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    const container = listContainerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const loadAnnounces = async (offset: number | null) => {
    if (offset === null) setItems([]);
    try {
      const filters = {
        offset: offset ? Math.ceil(offset) : 0,
        limit: 20,
        category: activeCategory,
        filters: filtersForm,
        label: undefined,
        sort: sort.value,
        radius: radius,
        lat: centerMap.lat,
        lng: centerMap.lng,
      };
      const response = await dispatch(getAnnounces(filters));
      unwrapResult(response);

      setItems(prevItems => [...prevItems, ...response.payload.data]);
    } finally {
    }
  };

  const loadMapAnnounces = () => {
    const filters = {
      offset: 0,
      limit: -1,
      category: activeCategory,
      filters: filtersForm,
      label: undefined,
      radius: radius,
      lat: centerMap.lat,
      lng: centerMap.lng,
    };

    dispatch(getMapAnnounces(filters));
  };

  return (
    <>
      <div className='flex-container display-flex'>
        <SideNav />
        <div className='display-flex flex-column main-wrapper'>
          <Header
            title='Harta'
            subtitle=''
            isbutton={false}
            backBtn={false}
            btnText=''
            breadcrumbs={[
              { link: '/', name: 'Home' },
              { link: '/map', name: 'Harta' },
            ]}
          />

          <>
            <div className='section-container map-page-section'>
              {isMobile && (
                <div
                  className='map-page-section__mobile-filters'
                  onClick={() => setOpenedFilters(true)}
                >
                  <span>Filters</span>
                  {filtersForm?.filter((filter: any) => filter)?.length > 0 && (
                    <span className='count'>
                      {filtersForm?.filter((filter: any) => filter)?.length}
                    </span>
                  )}{' '}
                </div>
              )}
              {(!isMobile || openedFilters) && (
                <MapFilters
                  openedFilters={openedFilters}
                  setOpenedFilters={setOpenedFilters}
                  filtersForm={filtersForm}
                  setFiltersForm={setFiltersForm}
                  activeCategory={activeCategory}
                  setActiveCategory={setActiveCategory}
                />
              )}

              <div
                className={`map-page-section__list filter-container ${openedList ? 'expanded' : ''}`}
              >
                <div className='map-page-section__list--top'>
                  <div className='map-page-section__list--title'>
                    {activeCategory === 0
                      ? 'Toate anunturile'
                      : categories.filter(
                          (category: Category) =>
                            category.id === activeCategory,
                        )[0].title}{' '}
                    {stateCountyId !== null
                      ? ` în Judeţul ${county.filter((countyItem: Locality) => countyItem.id === +stateCountyId)[0].title}`
                      : ''}
                  </div>
                  <div className='map-page-section__list--count'>
                    {totalCount} anunturi
                  </div>
                </div>
                <div className='map-page-section__list--sort'>
                  <label>Sortare</label>
                  <FormSelect
                    value={sort}
                    onChange={(e: SelectValue) => setSort(e)}
                    labelText={false}
                    placeholder={'Select Options'}
                    options={sortBy}
                  />
                </div>
                {isMobile && (
                  <div
                    className='map-page-section__items--show'
                    onClick={() => setOpenedList(!openedList)}
                  >
                    {openedList ? <IconArrowDownSmall /> : <IconArrowUp />}
                  </div>
                )}

                <div className='map-page-section__items' ref={listContainerRef}>
                  {items.map(item => (
                    <div
                      key={item.id + 'item'}
                      className={`map-page-section__item ${openedItem?.id === item.id ? 'active' : ''}`}
                      onClick={() => setOpenedItem(item)}
                    >
                      <div className='img-container'>
                        <img
                          src={
                            item.media &&
                            item.media.length > 0 &&
                            item.media[0].media?.path
                              ? getFilePath(item.media[0].media.path)
                              : ListItemImg
                          }
                          alt='img-item'
                        />
                      </div>
                      <div className='details-container'>
                        <div className='title-text'>
                          {getPriceType(item)} {item?.categoryTitle} zona{' '}
                          {item?.zoneLabel},
                        </div>
                        <div className='locality-text'>
                          {item?.townTitle ??
                            item?.countyTitle ??
                            item.zoneLabel}
                        </div>
                        <div className='item-info-tags'>
                          {hasValue(item.usableSurface) && (
                            <span className='label-item-tag'>
                              SU: {item.usableSurface}
                            </span>
                          )}
                          {hasValue(item.constructionYear) && (
                            <span className='label-item-tag'>
                              AN: {item.constructionYear}
                            </span>
                          )}
                          {hasValue(item.floorNumber) && (
                            <span className='label-item-tag'>
                              Etaj: {item.floorNumber}
                            </span>
                          )}
                          {hasValue(item.comfort) && (
                            <span className='label-item-tag'>
                              Confort: {item.comfort}
                            </span>
                          )}
                          {hasValue(item.floors) && (
                            <span className='label-item-tag'>
                              Nr etaje: {item.floors}
                            </span>
                          )}
                          {hasValue(item.numberOfRooms) && (
                            <span className='label-item-tag'>
                              Nr camere: {item.numberOfRooms}
                            </span>
                          )}
                          {hasValue(item.phones) &&
                            item.phones.map((phone: any) => (
                              <span
                                className='label-item-tag'
                                key={phone + 'phone'}
                              >
                                {phone}
                              </span>
                            ))}
                        </div>
                        <div className='price-text'>
                          {item.currencySymbol} {item.price}
                        </div>
                      </div>
                    </div>
                  ))}
                  {loading && (
                    <div
                      style={{
                        minHeight: '200px',
                        padding: '20px',
                        position: 'relative',
                      }}
                    >
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
              <div
                className='map-page-section__map'
                style={
                  isMobile
                    ? {
                        minHeight: 'calc(100vh - 165px)',
                        maxHeight: 'calc(100vh - 165px)',
                      }
                    : {}
                }
              >
                <div className='map-page-section__map--wrapper'>
                  {openedItem !== null && (
                    <div className='map-page-section__modal'>
                      <div className='map-page-section__modal--top'>
                        <span>
                          {getPriceType(openedItem)} {openedItem?.categoryTitle}{' '}
                          zona {openedItem?.zoneLabel},
                        </span>
                        <div
                          className='close-btn-modal'
                          onClick={() => setOpenedItem(null)}
                        >
                          {isMobile ? (
                            <IconClose />
                          ) : (
                            <img src={CloseIcon} alt='CloseIcon' />
                          )}
                        </div>
                      </div>
                      <div className='map-page-section__modal--content'>
                        <Gallery images={openedItem?.media} />
                        <div className='item-info-tags'>
                          {hasValue(openedItem?.usableSurface) && (
                            <span className='label-item-tag'>
                              SU: {openedItem?.usableSurface}
                            </span>
                          )}
                          {hasValue(openedItem?.constructionYear) && (
                            <span className='label-item-tag'>
                              AN: {openedItem?.constructionYear}
                            </span>
                          )}
                          {hasValue(openedItem?.floorNumber) && (
                            <span className='label-item-tag'>
                              Etaj: {openedItem?.floorNumber}
                            </span>
                          )}
                          {hasValue(openedItem?.comfort) && (
                            <span className='label-item-tag'>
                              Confort: {openedItem?.comfort}
                            </span>
                          )}
                          {hasValue(openedItem?.floors) && (
                            <span className='label-item-tag'>
                              Nr etaje: {openedItem?.floors}
                            </span>
                          )}
                          {hasValue(openedItem?.numberOfRooms) && (
                            <span className='label-item-tag'>
                              Nr camere: {openedItem?.numberOfRooms}
                            </span>
                          )}
                          {hasValue(openedItem?.phones) &&
                            openedItem?.phones.map((phone: any) => (
                              <span
                                className='label-item-tag'
                                key={phone + 'phone2'}
                              >
                                {phone}
                              </span>
                            ))}
                        </div>
                        <div className='locality-text'>
                          {openedItem?.countyTitle}{' '}
                          {openedItem?.townTitle
                            ? ', ' + openedItem?.townTitle
                            : ''}
                        </div>
                        <div
                          className='description-text'
                          dangerouslySetInnerHTML={{
                            __html: openedItem?.description,
                          }}
                        ></div>
                        <div className='price-text'>
                          {openedItem?.currencySymbol} {openedItem?.price}
                        </div>
                      </div>
                      {isMobile && (
                        <div className='back-btn__wrapper'>
                          <button
                            className='back-btn'
                            onClick={e => {
                              e.preventDefault();
                              setOpenedItem(null);
                            }}
                          >
                            Inapoi
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  <Map
                    activeCategory={activeCategory}
                    filtersForm={filtersForm}
                  />
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default MapPage;
