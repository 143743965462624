import React, { useState } from 'react';
import FilterListHeader from '../../components/FilterListHeader';
import FilterListHeaderPriceEvaluation from '../../components/FilterListHeaderPriceEvaluation';
import FilterListPriceEvaluation from '../../components/FilterListPriceEvaluation';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import Pagination from '../../components/Pagination';
import PriceEvaluationListItem from '../../components/PriceEvaluationListItem/PriceEvaluationListItem';
import PriceEvaluationRequestModal from '../../components/PriceEvaluationRequestModal';
import SideNav from '../../components/SideNav';
import './PriceEvaluation.scss';

interface Props {}

const PriceEvaluation: React.FC<Props> = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };
  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };
  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper price-evaluation-wrapper'>
        <Header
          title='Evaluare pret'
          subtitle=''
          // btnEvent={() => navigate('/add-contact')}
          isbutton={true}
          btnText='Evaluare Nou'
        />
        {!isMobile && <FilterListPriceEvaluation />}

        <FilterListHeader />
        <FilterListHeaderPriceEvaluation />

        <div className='list-items-container'>
          <PriceEvaluationListItem />
          <PriceEvaluationListItem />
          <PriceEvaluationListItem />
          <PriceEvaluationListItem />
          <PriceEvaluationListItem />
          <PriceEvaluationListItem />
        </div>
        <Pagination isSelectPage={false} />
        <FixedActions
          ModalAction={ModalAction}
          ModalFilter={ModalFilter}
          ModalSearch={ModalSearch}
        />
        <PriceEvaluationRequestModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          isAction={isAction}
          isSearch={isSearch}
          isFilter={isFilter}
        />
      </div>
    </div>
  );
};

export default PriceEvaluation;
